import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import BaseLayout from "../components/layout"
import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase"
import Seo from "../components/seo";
import ArticleMetadata from "../components/article-metadata";
import BookTOCDropdown from "../components/book-toc-dropdown";
import VerticalRule from "../components/vertical-rule";
import SocialIcon from "../components/social-icon";
import BreadcrumbTrail from "../components/breadcrumb-trail";
import ArticleImage from "/src/components/article-image";
import HorizontalRule from "/src/components/horizontal-rule";
import Logo from "../components/logo";
import NewsletterSignup from "../components/newsletter-signup";
import Editors from "../../editors";
import Tooltip from "../components/tooltip";
import PurchaseBook from "../components/purchase-book";
import NewsletterPopup from "../components/newsletter-popup";

export default class Article extends React.Component  {

  constructor() {
    super();

    this.state = {
      fontSize: "standard",
      showPictureBook: false,
      currentPictureIndex: 0
    };

    this.changeFontSize = this.changeFontSize.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.togglePictureBook = this.togglePictureBook.bind(this);
    this.nexPhoto = this.nexPhoto.bind(this);
    this.previousPhoto = this.previousPhoto.bind(this);
  }

  changeFontSize(fontSize) {
    this.setState({
      fontSize: fontSize
    });
    localStorage.setItem('article-font-size', fontSize)
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  togglePictureBook() {
    this.setState({
      showPictureBook: !this.state.showPictureBook
    });
  }

  nexPhoto() {
    let currentPictureIndex = this.state.currentPictureIndex;
    currentPictureIndex += 1;
    if (currentPictureIndex > this.props.data.articleMdx.nodes[0].frontmatter.picture_book.length - 1) {
      currentPictureIndex = 0
    }
    this.setState({
      currentPictureIndex: currentPictureIndex
    })
  }

  previousPhoto() {
    let currentPictureIndex = this.state.currentPictureIndex;
    currentPictureIndex -= 1;
    if (currentPictureIndex < 0) {
      currentPictureIndex = this.props.data.articleMdx.nodes[0].frontmatter.picture_book.length - 1;
    }
    this.setState({
      currentPictureIndex: currentPictureIndex
    })
  }

  render() {
    const article = this.props.data.articleMdx.nodes[0];
    const site = this.props.data.site

    let articleFormat;
    if (article.frontmatter.collection_type === 'chapter') {
      articleFormat = 'chapter';
    } else {
      articleFormat = 'feed';
    }

    let articleList = this.props.data.collectionMdx.nodes.sort((a, b) => {
      if (articleFormat === 'feed') {
        if (a.frontmatter.published_date < b.frontmatter.published_date) {
          return -1
        }
        return 1;
      }

      if (a.frontmatter.chapter < b.frontmatter.chapter) {
        return -1
      }
      return 1;
    })

    let lastNode;
    let previousChapter;
    let nextChapter;
    let nextChapterUnlocked = false;
    for (let node of articleList) {
      if (nextChapterUnlocked) {
        nextChapter = node;
        break;
      }
      if (node.frontmatter.chapter === article.frontmatter.chapter) {
        previousChapter = lastNode;
        nextChapterUnlocked = true;
      }
      lastNode = node;
    }
    const isFirstChapter = article.frontmatter.chapter === 1;
    const isLastChapter = article.frontmatter.chapter === articleList.length;

    const displayChapterCount = 7
    const chaptersFromEnd = articleList.length - article.frontmatter.chapter;
    let lastChapterDisplay;
    let firstChapterDisplay;
    if (chaptersFromEnd >= Math.floor(displayChapterCount/2)) {
      lastChapterDisplay = article.frontmatter.chapter + Math.floor(displayChapterCount/2);
    } else {
      lastChapterDisplay = articleList.length;
    }
    const chaptersDisplayAtOrAfterCurrent = (lastChapterDisplay - article.frontmatter.chapter) + 1;
    if (chaptersDisplayAtOrAfterCurrent < displayChapterCount) {
      firstChapterDisplay = article.frontmatter.chapter - (displayChapterCount - chaptersDisplayAtOrAfterCurrent);
    }
    if (firstChapterDisplay < 1) {
      firstChapterDisplay = 1;
    }
    if ((lastChapterDisplay - firstChapterDisplay + 1) < displayChapterCount) {
      const chapterDeficiency = displayChapterCount - (lastChapterDisplay - firstChapterDisplay + 1);
      const remainingChaptersAfterCurrentLast = articleList.length - lastChapterDisplay;
      if (chapterDeficiency < remainingChaptersAfterCurrentLast) {
        lastChapterDisplay += chapterDeficiency;
      } else {
        lastChapterDisplay += remainingChaptersAfterCurrentLast;
      }
    }

    let paginationArticleList = articleList.slice(firstChapterDisplay - 1, lastChapterDisplay);

    let seoDescription;
    if (article.frontmatter.seo_description) {
      seoDescription = article.frontmatter.seo_description;
    } else {
      seoDescription = article.frontmatter.summary;
    }

    let book = this.props.data.book.nodes[0];

    function pad(num, size) {
        var s = "000000000" + num;
        return s.substr(s.length-size);
    }

    let fontSizeLayoutClass;
    let cachedFontSize = null;
    if (typeof localStorage !== 'undefined') {
      cachedFontSize = localStorage.getItem('article-font-size')
    }
    if (cachedFontSize === null) {
      cachedFontSize = 'standard';
    }
    if (cachedFontSize === 'standard') {
      fontSizeLayoutClass = 'article-layout--standardFont'
    } else if (cachedFontSize === 'large') {
      fontSizeLayoutClass = 'article-layout--largeFont'
    }

    let verticalRule = 'skutull-a';
    if (article.frontmatter.vertical_rule_layout) {
      verticalRule = article.frontmatter.vertical_rule_layout;
    }

    let breadcrumbTitle = book.title
    if (book.subtitle) {
      breadcrumbTitle = book.title + ' ' + book.subtitle
    }

    let breadcrumbList = [
      [
        {
          title: 'Home',
          url: '/'
        },
        {
          title: 'Library',
          url: '/writing/library/'
        },
        {
          title: breadcrumbTitle,
          url: `/writing/library/${kebabCase(book.title)}`
        },
        {
          title: article.frontmatter.title,
        },
      ]
    ]
    for (let tag of article.frontmatter.tags) {
      breadcrumbList.push([
        {
          title: 'Home',
          url: '/'
        },
        {
          title: 'Library',
          url: '/writing/library/'
        },
        {
          title: `"${tag}" Chapters`,
          url: `/writing/genres/${kebabCase(tag)}`
        },
        {
          title: article.frontmatter.title,
        },
      ])
    }

    return (
      <BaseLayout>
        <Seo title={article.frontmatter.title} desc={seoDescription} banner={article.frontmatter.banner_image} pathname={this.props.pageContext.slug} breadcrumbList={breadcrumbList}></Seo>

        <div className="layout-base-w app-p-base">
          <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
          <Link to={`/writing/library/${kebabCase(book.title)}`}><h2 className="text-subheader-1 text-mint mb-4">{book.title}{book.subtitle && <> {book.subtitle}</>}</h2></Link>
          <h1 className="text-header-1 text-mint">{article.frontmatter.title}</h1>

          <div className="w-full overflow-hidden relative">
            <VerticalRule ruleType={verticalRule}></VerticalRule>
            <h6 className="text-subheader-1 w-full text-center absolute-center mt-4">Chapter {article.frontmatter.chapter}</h6>
          </div>

          <div className="border-t-base border-b-base border-white py-8 flex justify-between md:items-center md:flex-row flex-col">
            <div className="flex-1">
              <ArticleMetadata genreList={article.frontmatter.tags} publishedDate={article.frontmatter.formatted_published_date}></ArticleMetadata>
              {article.frontmatter.collection === "Nomad Life" &&
                <div className="mt-4">
                  <h5 className="text-subheader-3 -mt-2">
                    Location: {article.frontmatter.travel_location}
                  </h5>
                  <h5 className="text-subheader-3 mt-2">
                    Travel Dates: {article.frontmatter.travel_dates}
                  </h5>
                  <h5 className="text-subheader-3 mt-2 -mb-2">
                    Days Nomadic: {article.frontmatter.cumulative_days_nomadic}
                    <span>&nbsp;·&nbsp;</span>
                    Miles Traveled: {article.frontmatter.cumulative_miles_traveled}
                  </h5>
                </div>
              }
            </div>
            <div className="flex-1 mt-8 md:mt-0 flex justify-center md:justify-end">
              <PurchaseBook book={book} displayFormat={'bare'}></PurchaseBook>
            </div>
          </div>

          <div className="hidden md:flex justify-between mt-8">
            <div className="flex-1 grow-0">
              {!isFirstChapter &&
                <Link to={`/${previousChapter.slug}`}><button className="btn btn-wide btn-white" style={{'width': '225px'}}>← Last</button></Link>
              }
              {isFirstChapter &&
                <div style={{'width': '225px'}}></div>
              }
            </div>

            <div className="flex-1 grow px-8">
              <BookTOCDropdown chapters={articleList} dropdownTitle="Chapters" borderColor="green" activeChapter={article} constrainWidth={false}></BookTOCDropdown>
            </div>

            <div className="flex-1 grow-0">
              {!isLastChapter &&
                <Link to={`/${nextChapter.slug}`}><button className="btn btn-wide btn-white" style={{'width': '225px'}}>Next →</button></Link>
              }
              {isLastChapter &&
                <div style={{'width': '225px'}}></div>
              }
            </div>
          </div>

          <div className="md:hidden">
            <div className="flex justify-between gap-8 mt-8">

              {!isFirstChapter &&
                <div className="flex-1 grow shrink">
                  <Link to={`/${previousChapter.slug}`}><button className="btn btn-full btn-white">← Last</button></Link>
                </div>
              }

              {!isLastChapter &&
                <div className="flex-1 grow shrink">
                  <Link to={`/${nextChapter.slug}`}><button className="btn btn-full btn-white">Next →</button></Link>
                </div>
              }

            </div>
            <div className="mt-8">
              <BookTOCDropdown chapters={articleList} dropdownTitle="Chapters" borderColor="green" activeChapter={article} constrainWidth={false}></BookTOCDropdown>
            </div>
          </div>

          <div className="flex justify-center items-center mt-2 [margin-right:0px] sm:[margin-right:72px]">
            {paginationArticleList.map((chapter, index) => (
              <div key={index} className="flex items-center">
                {chapter.frontmatter.chapter === article.frontmatter.chapter &&
                  <span className="text-pagination-decoration" style={{'margin': '0px 3px'}}>{pad(article.frontmatter.chapter, 2)}</span>
                }
                {chapter.frontmatter.chapter !== article.frontmatter.chapter &&
                  <Link to={`/${chapter.slug}`}><span className="text-pagination text-mint text-link" style={{'margin': '0px 3px'}}>{pad(chapter.frontmatter.chapter, 2)}</span></Link>
                }

                {chapter.frontmatter.chapter < paginationArticleList[paginationArticleList.length - 1].frontmatter.chapter &&
                  <span className="text-pagination text-mint" style={{'margin': '0px 3px'}}>|</span>
                }
              </div>
            ))}
            <span className="text-pagination-decoration" style={{'marginLeft': '8px'}}>of {articleList.length}</span>
          </div>

          <div className="mt-16 md:mt-28 flex md:flex-row-reverse flex-col justify-end items-start">
            <div className={`article-layout ${fontSizeLayoutClass} reading-w`} id="articleBodyContainer">
              <MDXRenderer>{article.body}</MDXRenderer>

              <NewsletterPopup></NewsletterPopup>

              {(article.frontmatter.picture_book && article.frontmatter.picture_book.length > 0) &&
                <div role="button" onClick={this.togglePictureBook} onKeyDown={this.togglePictureBook} className="p-8 mt-16 w-full btn-white">
                  <div className="flex justify-center items-center">
                    <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M1 22H29V1H1V22Z" stroke="currentColor" strokeWidth="2"/>
                      <path d="M1 15.1897L10.366 6.9729L15 11.4996L19.1347 8.8734L29 18.2441" stroke="currentColor" strokeWidth="2"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M25.8665 6.05289C25.8665 4.94806 24.9717 4.05322 23.8669 4.05322C22.762 4.05322 21.8672 4.94806 21.8672 6.05289C21.8672 7.15772 22.762 8.05256 23.8669 8.05256C24.9717 8.05256 25.8665 7.15772 25.8665 6.05289Z" fill="currentColor"/>
                    </svg>
                    <h6 className="text-subheader-1 exclude-markdown-styling ml-8">Open Picture Book</h6>
                  </div>
                </div>
              }

              {(article.frontmatter.editors && article.frontmatter.editors.length > 0) && (
                <div className="mt-20 opacity-80 text-green">
                  <h6 className="exclude-markdown-styling text-subheader-3 mb-4">Edited By:&nbsp;
                    {article.frontmatter.editors.length === 1 && (
                      <span><a href={Editors.Editors[article.frontmatter.editors[0]].url} className="text-link" target="_blank" rel="noopener noreferrer">{Editors.Editors[article.frontmatter.editors[0]].name}</a></span>
                    )}
                    {article.frontmatter.editors.length === 2 && (
                      <span><a href={Editors.Editors[article.frontmatter.editors[0]].url} className="text-link" target="_blank" rel="noopener noreferrer">{Editors.Editors[article.frontmatter.editors[0]].name}</a> & <a href={Editors.Editors[article.frontmatter.editors[1]].url} className="text-link" target="_blank" rel="noopener noreferrer">{Editors.Editors[article.frontmatter.editors[1]].name}</a></span>
                    )}
                    {article.frontmatter.editors.length === 3 && (
                      <span><a href={Editors.Editors[article.frontmatter.editors[0]].url} className="text-link" target="_blank" rel="noopener noreferrer">{Editors.Editors[article.frontmatter.editors[0]].name}</a>, <a href={Editors.Editors[article.frontmatter.editors[1]].url} className="text-link" target="_blank" rel="noopener noreferrer">{Editors.Editors[article.frontmatter.editors[1]].name}</a>, & <a href={Editors.Editors[article.frontmatter.editors[2]].url} className="text-link" target="_blank" rel="noopener noreferrer">{Editors.Editors[article.frontmatter.editors[2]].name}</a></span>
                    )}
                  </h6>
                </div>
              )}

              {(article.frontmatter.references && article.frontmatter.references.length > 0) && (
                <div className="mb-20 mt-6 opacity-80 text-green">
                  <h6 className="exclude-markdown-styling text-subheader-3 mb-2">References:</h6>
                  <ol className="references">
                    {article.frontmatter.references.map((reference, index) => (
                      <li key={index}><a href={reference} className="text-link">{reference}</a></li>
                    ))}
                  </ol>
                </div>
              )}

              {book.purchase.display && !nextChapter && book.title !== "Tales of Twilight" &&
                <h6 className="exclude-markdown-styling text-mint text-subheader-2 italic mt-20">This is the last free chapter of "{book.title}" available on my website. If you'd like to continue reading, you can <a className="text-link" href={book.purchase.url} target="_blank" rel="noopener noreferrer">purchase the full book here</a>.</h6>
              }
              {book.purchase.display && nextChapter && book.title !== "Tales of Twilight" &&
                <h6 className="exclude-markdown-styling text-mint text-subheader-2 italic mt-20">There are more free chapters of "{book.title}" available on my website, but if you're ready to purchase the full book, you can <a className="text-link" href={book.purchase.url} target="_blank" rel="noopener noreferrer">do so here</a>.</h6>
              }

              {book.purchase.display && book.title === "Tales of Twilight" &&
                <h6 className="exclude-markdown-styling text-mint text-subheader-2 italic mt-20">Sign up for my reader group and newsletter, <a className="text-link" href="/newsletter/the-storied-scrolls">The Storied Scrolls</a>, and I'll send you a free eBook copy of "{book.title}."</h6>
              }


              <div className="text-mint mt-20">
                <HorizontalRule ruleType="7"></HorizontalRule>
              </div>
            </div>

            <div className="hidden md:block md:sticky top-0 pt-12 -mt-7 pr-8 box-content text-center" style={{'width': '140px'}}>
              <div className="relative group mb-12">
                <h6 className="block w-full text-center text-button">Share</h6>
                <div className="w-full pt-4 flex justify-center items-center">
                  <div className="text-mint hover:text-lightblue active:text-black">
                    <SocialIcon siteData={site.siteMetadata} icon="url-link" iconLinkType="share" urlLink={article.slug}></SocialIcon>
                  </div>
                </div>
              </div>

              <Link to={'/contact'}>
                <div className="relative group cursor-pointer my-12">
                  <h6 className="block w-full text-center text-button">Follow</h6>
                  <div className="w-full pt-4 flex justify-center items-center">
                    <div className="text-mint hover:text-lightblue active:text-black">
                      <svg width="24" height="24" viewBox="0 0 112 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_2211_303)">
                          <path
                            d="M61.6 22.5C61.6 34.9277 51.5725 45 39.2 45C26.8275 45 16.8 34.9277 16.8 22.5C16.8 10.0723 26.8275 0 39.2 0C51.5725 0 61.6 10.0723 61.6 22.5ZM67.2 53.4375L78.4 90H0L11.2 53.4375H67.2ZM112 90H84.2625L83.755 88.3477L73.92 56.25H100.8L112 90ZM75.6 45C70.175 45 65.275 42.7852 61.7225 39.2168C65.17 34.541 67.2 28.7578 67.2 22.5C67.2 17.7891 66.045 13.3418 63.9975 9.43945C67.2525 7.04883 71.26 5.625 75.6 5.625C86.4325 5.625 95.2 14.4316 95.2 25.3125C95.2 36.1934 86.4325 45 75.6 45Z"
                            fill="currentColor"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_2211_303">
                            <rect width="112" height="90" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </Link>

              <PurchaseBook book={book} displayFormat={'toolbar'}></PurchaseBook>

              <h6 className="text-subheader-4 mt-12">Text</h6>
              <div className="TextSizeSelector w-full flex justify-center items-center py-8">
                <h6 className="text-header-2 text-mint">Aa</h6>
                <div className={`TextSizeSelector_menu bg-offwhite cursor-pointer`}>
                  <div className={`TextSizeSelector_menuItem ${cachedFontSize === 'standard' ? 'TextSizeSelector_menuItem--selected': 'TextSizeSelector_menuItem--unselected'} p-8 flex justify-center items-center hover:bg-lightblue`} onClick={() => this.changeFontSize('standard')} role="presentation">
                    <div>
                      <h6 className="text-header-2">Aa</h6>
                      <h6 className="text-subheader-3">Small</h6>
                    </div>
                  </div>
                  <div className={`TextSizeSelector_menuItem ${cachedFontSize === 'large' ? 'TextSizeSelector_menuItem--selected': 'TextSizeSelector_menuItem--unselected'} p-8 flex justify-center items-center hover:bg-lightblue`} onClick={() => this.changeFontSize('large')} role="presentation">
                    <div>
                      <h6 className="text-header-1">Aa</h6>
                      <h6 className="text-subheader-3">Large</h6>
                    </div>
                  </div>
                </div>
              </div>

              {(article.frontmatter.picture_book && article.frontmatter.picture_book.length > 0) &&
                <div className="mt-2">
                  <h6 className="text-subheader-4">Picture Book</h6>
                  <div role="presentation" onClick={this.togglePictureBook} onKeyDown={this.togglePictureBook} className="text-mint p-8 flex justify-center items-center hover:text-lightblue cursor-pointer">
                    <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M1 22H29V1H1V22Z" stroke="currentColor" strokeWidth="2"/>
                      <path d="M1 15.1897L10.366 6.9729L15 11.4996L19.1347 8.8734L29 18.2441" stroke="currentColor" strokeWidth="2"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M25.8665 6.05289C25.8665 4.94806 24.9717 4.05322 23.8669 4.05322C22.762 4.05322 21.8672 4.94806 21.8672 6.05289C21.8672 7.15772 22.762 8.05256 23.8669 8.05256C24.9717 8.05256 25.8665 7.15772 25.8665 6.05289Z" fill="currentColor"/>
                    </svg>
                  </div>
                </div>
              }

              <button className="btn btn-full btn-white mt-4" onClick={this.scrollToTop} onKeyDown={this.scrollToTop}>Back to Top</button>
            </div>
          </div>

          <div className="my-40">
            <NewsletterSignup textAlign="left"></NewsletterSignup>
          </div>

          <div className="flex justify-between items-center w-full border-t-base border-b-base border-black mt-20">
            <div className="hidden md:block flex-1 text-left">
              {articleList.length > 1 && (
                <h6 className="text-subheader-3">Continue Reading</h6>
              )}
            </div>
            <div className="flex-1 flex justify-between md:justify-center items-center">
              {previousChapter && (
                <Link to={`/${previousChapter.slug}`}>
                  <button className="btn btn-skinny btn-white">←</button>
                </Link>
              )}
              {!previousChapter && (
                <div style={{'width': '50px', 'height': '42px'}}></div>
              )}
              <div className="px-8 flex items-center justify-center">
                <h6 className="text-pagination-decoration">{pad(article.frontmatter.chapter, 2)} of {pad(articleList.length, 2)}</h6>
              </div>
              {nextChapter && (
                <Link to={`/${nextChapter.slug}`}>
                  <button className="btn btn-skinny btn-white">→</button>
                </Link>
              )}
              {!nextChapter && (
                <div style={{'width': '50px', 'height': '42px'}}></div>
              )}
            </div>
            <div className="hidden md:block flex-1 text-right">
              <h6 className="text-subheader-3">Chapter {article.frontmatter.chapter}</h6>
            </div>
          </div>
        </div>

        {this.state.showPictureBook && (
          <div className="fixed top-0 left-0 w-screen h-screen bg-mint text-white z-50 overflow-scroll">
            <div className="layout-base-w app-py-base mx-auto">
              <div className="flex px-16 mb-16 justify-end">
                <span className="text-header-2 hover:text-lightblue cursor-pointer" onClick={this.togglePictureBook} onKeyDown={this.togglePictureBook}>X</span>
              </div>
              <h6 className="text-subheader-3 text-center mb-4 px-16">{article.frontmatter.title} | Photo {this.state.currentPictureIndex + 1}</h6>
              <div className="flex justify-center gap-4 items-start sm:px-16">
                <div className="self-stretch flex justify-between items-center flex-1 shrink-0 grow-0 md:[height:600px]">
                  <button className="btn btn-skinny btn-white" onClick={this.previousPhoto} onKeyDown={this.previousPhoto}>←</button>
                </div>
                <div className="flex-1 grow shrink flex justify-center items-center">
                  <ArticleImage imageMaxHeight="600px" captionMaxWidth="460px" src={article.frontmatter.picture_book[this.state.currentPictureIndex].src} caption={article.frontmatter.picture_book[this.state.currentPictureIndex].caption} aspectRatio={article.frontmatter.picture_book[this.state.currentPictureIndex].aspectRatio} type={article.frontmatter.picture_book[this.state.currentPictureIndex].type} />
                </div>
                <div className="self-stretch flex justify-between items-center flex-1 shrink-0 grow-0 md:[height:600px]">
                  <button className="btn btn-skinny btn-white" onClick={this.nexPhoto} onKeyDown={this.nexPhoto}>→</button>
                </div>
              </div>
              <div className="mt-8 text-white flex justify-center items-center">
                <Logo logoType="trademark" invertColor={true}></Logo>
              </div>

            </div>
          </div>
        )}

      </BaseLayout>
    )
  }

}

export const pageQuery = graphql`
  fragment ArticleFrontmatter on Mdx {
    frontmatter {
      record_type
      title
      published_date(formatString: "YYYY-MM-DD")
      formatted_published_date: published_date(formatString: "MMM Do, YYYY")
      published_year: published_date(formatString: "YYYY")
      banner_image
      published
      tags
      collection
      collection_type
      chapter
      summary
      seo_description
      cumulative_days_nomadic
      cumulative_miles_traveled
      travel_dates
      travel_location
      vertical_rule_layout
      picture_book {
        src
        caption
        aspect_ratio
        type
      }
      references
      editors
    }
  }

  fragment ArticleDetails on Mdx {
    id
    slug
    excerpt(pruneLength: 250)
    body
    ...ArticleFrontmatter
  }

  query($slug: String!, $collection: String!) {
    articleMdx: allMdx(
      filter: {slug: { eq: $slug }, frontmatter: { published: { eq: true }, record_type: { eq: "story" } } }
    ) {
      nodes {
        ...ArticleDetails    
      }
    }
    
    collectionMdx: allMdx(
      sort: { fields: [frontmatter___published_date, frontmatter___chapter], order: [DESC, ASC] }
      filter: {frontmatter: { published: { eq: true }, record_type: { eq: "story" }, collection: { eq: $collection } } }
    ) {
      nodes {
        ...ArticleDetails    
      }
    }
    
    book: allCollections(
      filter: { title: { eq: $collection } }
      limit: 1
    ) {
      nodes {
        ...CollectionFields
      }
    }
    
    site {
      siteMetadata {
        ...CommonMetaData
      }
    }
  }
`


