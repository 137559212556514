import React from "react";
import TextBlock from "./text-block";
import Logo from "./logo";
import { Link } from "gatsby";
import NewsletterSignup from "./newsletter-signup";
import ArticleImage from "./article-image";

const NEWSLETTER_POPUP_KEY = 'newsletterPopupTriggered';

export default class NewsletterPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      popupTriggerInView: false,
    };

    this.popupLastChecked = Date.now();
    this.pageLoadedAt = Date.now();

    const popupNumber = Math.floor(Math.random() * 1000000000) + 1;
    this.popupId = 'NewsletterPopup:' + popupNumber.toString();
    this.addScrollListener()
  }

  get popupTriggered() {
    if (typeof window !== `undefined`) {
      return window.localStorage.getItem(NEWSLETTER_POPUP_KEY)
    }
    return true
  }

  addScrollListener() {
    if (!this.popupTriggered) {
      if (typeof window !== `undefined`) {
        window.addEventListener('scroll', this.scrollListener.bind(this));
      }
    }
  }

  scrollListener(event) {
    if (this.popupTriggered) {
      return
    }

    const currentTime = Date.now();
    const timeOnPage = currentTime - this.pageLoadedAt;

    // Don't check the popup too often or it slows down browser
    if (currentTime - this.popupLastChecked < 250) {
      return
    }
    // Don't show popup unless user has been on the page at least a minute
    if (timeOnPage < 60) {
      return
    }

    this.popupLastChecked = currentTime;

    let el = document.getElementById(this.popupId);
    // Show the popup if the user has reached the end of the story or has been on the page for >5min
    if (this.isElementInViewport(el) || timeOnPage > 300000) {
      this.setState(prevState => ({
        popupTriggerInView: true,
      }))
      window.localStorage.setItem(NEWSLETTER_POPUP_KEY, true)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener.bind(this));
  }

  isElementInViewport(el) {
    if (!el) {
      return false;
    }

    let rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  collapsePopup() {
    this.setState(prevState => ({
      popupTriggerInView: false
    }))
  }

  render() {

    return (
      <>
        {this.state.popupTriggerInView &&
          <div className="fixed w-full h-full inset-0 z-10 flex items-center justify-center" style={{'backgroundColor': 'rgba(0, 0, 0, 0.5)'}}>
            <div className="w-full max-w-2xl bg-offwhite p-10 mx-6">
              <div className="flex mb-4 justify-end">
                <span className="text-header-2 hover:text-lightblue cursor-pointer" onClick={this.collapsePopup.bind(this)} onKeyDown={this.collapsePopup.bind(this)}>X</span>
              </div>
              <h2 className="text-header-2 exclude-markdown-styling text-mint my-2">Reader Group & Newsletter</h2>
              <p className="text-body-1 exclude-markdown-styling">Sign up to become a member of reader group & newsletter, The Storied Scrolls, and I'll send you a free eBook copy of <a className="text-link" href="/writing/library/tales-of-twilight" target="_blank" rel="noopener noreferrer"><i>Tales of Twilight</i></a> to get you started.</p>

              <div className="my-8">
                <ArticleImage src="https://media.zackthoutt.com/img/tales-of-twilight/tales-of-twilight-marketing@website-seo-image.jpg" alt="Image of Tales of Twilight Book"></ArticleImage>
              </div>

              <NewsletterSignup ctaLength={'form-only'}></NewsletterSignup>
            </div>
          </div>
        }
        <div id={this.popupId}></div>
      </>
    )
  }

}
