
exports.Editors = {
  shelby: {
    id: 'shelby',
    name: "Shelby Thoutt",
    url: 'https://shelbythoutt.com'
  },
  adele: {
    id: 'adele',
    name: "Adele Jordan",
    url: "https://www.adelejordanghostwriter.com/"
  },
  brooks: {
    id: 'brooks',
    name: 'Brooks Becker',
    url: "https://reedsy.com/brooks-becker"
  }
}